<template>
  <main class="w-full">
    <section class="relative mt-8 md:mt-16 container">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-10 xl:gap-x-16">
        <div
          class="flex flex-col sm:flex-row lg:flex-col justify-center items-center"
        >
          <div
            class="w-full flex items-center justify-between space-x-4 sm:space-x-10"
          >
            <button
              @click="subIndexOfProductImages"
              class="focus:outline-none hover:scale-105 duration-300 transform"
            >
              <svg
                width="12"
                height="23"
                viewBox="0 0 12 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11 22L2 11.5L11 1" stroke="black" stroke-width="2" />
              </svg>
            </button>

            <div class="bg-white">
              <img
                :src="productImage"
                alt=""
                class="w-full object-contain h-64 sm:h-96"
              />
            </div>

            <button
              @click="addIndexOfProductImages"
              class="focus:outline-none hover:scale-105 duration-300 transform"
            >
              <svg
                width="13"
                height="23"
                viewBox="0 0 13 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L11 11.5L1 22" stroke="black" stroke-width="2" />
              </svg>
            </button>
          </div>
          <div
            class="mt-8 w-full flex flex-row sm:flex-col lg:flex-row lg:space-x-2 overflow-x-auto"
          >
            <button
              v-for="(img, index) in productImages"
              :key="index"
              @click="changeMainPicture(img.productImage, index)"
              :class="
                img.productImage == productImage
                  ? 'border border-primary-black'
                  : ''
              "
              class="w-20 h-20 flex justify-center items-center focus:outline-none"
            >
              <div class="overflow-hidden w-16 h-16">
                <img
                  :src="img.productImage"
                  alt=""
                  class="w-full h-full object-cover hover:scale-105 transform duration-500"
                />
              </div>
            </button>
          </div>
        </div>

        <div class="mt-10 lg:mt-0">
          <div>
            <div class="flex justify-between items-center">
              <p class="text-lg sm:text-2xl font-bold text-grey-900">
                {{ productInfo.productName }}
              </p>

              <p class="text-sm sm:text-lg text-grey-500">
                {{ productInfo.categoryName }}
              </p>
            </div>

            <hr class="mt-5 mb-7 w-full text-grey-100" />
            <div class="">
              <p class="text-grey-700 text-xs sm:text-sm">
                PRODUCT DESCRIPTION
              </p>
              <p class="text-grey-900 text-xs sm:text-sm leading-7 mt-3">
                {{ productInfo.descripton }}
              </p>
            </div>
          </div>

          <div
            class="grid sm:grid-cols-2 mt-8 md:mt-12 gap-y-6 sm:gap-y-12 md:gap-y-20"
          >
            <div>
              <p class="text-grey-700 text-xs tracking-widest uppercase">
                COLORS AVAILABLE
              </p>
              <div class="flex flex-wrap mt-3 pr-6">
                <button
                  @click="Addcolor(colorFromLooping)"
                  v-for="(colorFromLooping, index) in filters"
                  :key="index"
                  v-bind:style="{
                    backgroundColor: colorFromLooping.hexadecimal,
                  }"
                  class="w-8 h-8 mr-2 mb-2 rounded-full focus:outline-none hover:scale-105 duration-300 transform"
                  :class="
                    colorFromLooping == coloradded
                      ? 'border bg-primary-black shadow'
                      : 'border border-grey-100'
                  "
                ></button>
              </div>
            </div>

            <div>
              <div v-if="coloradded != '' && sizeTypeId == 1" class="">
                <p class="text-grey-700 text-xs tracking-widest uppercase">
                  SIZES AVAILABLE
                </p>
                <div class="flex flex-wrap mt-3">
                  <button
                    @click="choicesize(sizeFromLooping)"
                    v-for="(sizeFromLooping, index) in sizes"
                    :key="index"
                    :class="sizeFromLooping == size ? 'border-2' : 'border'"
                    class="focus:outline-none uppercase px-4 py-2 text-sm m-2 border-grey-700 hover:scale-105 duration-300 transform"
                  >
                    {{ sizeFromLooping.sizeName }}
                  </button>
                </div>
              </div>

              <div v-if="coloradded != '' && sizeTypeId == 2" class="">
                <p class="text-grey-700 text-xs tracking-widest uppercase">
                  SIZES AVAILABLE <span class="text-black font-bold">H/W</span>
                </p>
                <div class="flex flex-wrap mt-3">
                  <button
                    @click="choicesize(sizeFromLooping)"
                    v-for="(sizeFromLooping, index) in sizes"
                    :key="index"
                    :class="sizeFromLooping == size ? 'border-2' : 'border'"
                    class="focus:outline-none uppercase px-4 py-2 text-sm m-2 border-grey-700 hover:scale-105 duration-300 transform"
                  >
                    {{ sizeFromLooping.height }}/{{ sizeFromLooping.width }}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div v-if="size != 0">
                <p class="text-grey-700 text-xs tracking-widest uppercase">
                  Price
                </p>
                <p class="text-lg font-bold text-grey-900 mt-3">
                  {{ size.price }} LYD
                </p>
              </div>
            </div>

            <div>
              <div v-if="size != 0">
                <p class="text-grey-700 text-xs tracking-widest uppercase">
                  QUANITITY
                </p>
                <div class="mt-3 flex items-center space-x-3">
                  <button
                    @click="quantityFun('negative')"
                    class="focus:outline-none hover:scale-105 duration-300 transform rounded-full"
                    :class="
                      decreaseQuantity ? 'cursor-pointer' : 'cursor-not-allowed'
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 12.0001C15 12.0001 11.3431 12.0001 9 12.0001M21 12.0001C21 13.182 20.7672 14.3523 20.3149 15.4442C19.8626 16.5361 19.1997 17.5283 18.364 18.364C17.5282 19.1997 16.5361 19.8627 15.4442 20.315C14.3522 20.7673 13.1819 21.0001 12 21.0001C10.8181 21.0001 9.64778 20.7673 8.55585 20.315C7.46392 19.8627 6.47177 19.1997 5.63604 18.364C4.80031 17.5283 4.13738 16.5361 3.68508 15.4442C3.23279 14.3523 3 13.182 3 12.0001C3 9.61311 3.94821 7.32393 5.63604 5.6361C7.32387 3.94827 9.61305 3.00006 12 3.00006C14.3869 3.00006 16.6761 3.94827 18.364 5.6361C20.0518 7.32393 21 9.61311 21 12.0001Z"
                        stroke="#515151"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>

                  <div
                    class="bg-surface-grey border border-grey-100 text-lg font-bold px-3"
                  >
                    {{ quantity }}
                    <span class="text-grey-300">/ {{ size.qty }}</span>
                  </div>

                  <button
                    @click="quantityFun('positive')"
                    class="focus:outline-none hover:scale-105 duration-300 transform rounded-full"
                    :class="
                      addQuantity ? 'cursor-pointer' : 'cursor-not-allowed'
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 9.00006V12.0001V9.00006ZM12 12.0001V15.0001V12.0001ZM12 12.0001H15H12ZM12 12.0001H9H12ZM21 12.0001C21 13.182 20.7672 14.3523 20.3149 15.4442C19.8626 16.5361 19.1997 17.5283 18.364 18.364C17.5282 19.1997 16.5361 19.8627 15.4442 20.315C14.3522 20.7673 13.1819 21.0001 12 21.0001C10.8181 21.0001 9.64778 20.7673 8.55585 20.315C7.46392 19.8627 6.47177 19.1997 5.63604 18.364C4.80031 17.5283 4.13738 16.5361 3.68508 15.4442C3.23279 14.3523 3 13.182 3 12.0001C3 9.61311 3.94821 7.32393 5.63604 5.6361C7.32387 3.94827 9.61305 3.00006 12 3.00006C14.3869 3.00006 16.6761 3.94827 18.364 5.6361C20.0518 7.32393 21 9.61311 21 12.0001Z"
                        stroke="#515151"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full mt-7">
            <button
              :class="
                size == '' || coloradded == ''
                  ? 'pointer-events-none bg-grey-500'
                  : ''
              "
              @click="addToCart()"
              class="w-full text-base bg-primary-black text-surface-white font-normal px-8 lg:px-12 py-2 lg:py-3 border border-primary-black rounded hover:text-grey-900 hover:bg-surface-white transform duration-700"
            >
              ADD TO BAG
            </button>
          </div>
        </div>
      </div>

      <div
        class="grid sm:grid-cols-2 md:grid-cols-4 gap-y-6 sm:gap-y-10 gap-x-10 md:gap-y-0 xl:gap-x-16 mt-14"
      >
        <div class="">
          <p class="text-grey-700 text-xs tracking-widest uppercase">
            DESIGNER
          </p>

          <router-link
            :to="{ name: 'producer', params: { id: productInfo.producerId } }"
            class="mt-3 text-lg text-grey-900 font-bold underline block"
          >
            {{ productInfo.producerName }}
          </router-link>
        </div>

        <div class="">
          <p class="text-grey-700 text-xs tracking-widest uppercase">
            RELEASE DATE
          </p>
          <p class="mt-3 text-lg text-grey-900 font-bold">
            {{ productInfo.createdOn }}
          </p>
        </div>

        <div class="">
          <p class="text-grey-700 text-xs tracking-widest uppercase">
            ARTWORK GENRE
          </p>
          <p class="mt-3 text-lg text-grey-900 font-bold">Calligraphy</p>
        </div>

        <div class="">
          <p class="text-grey-700 text-xs tracking-widest uppercase">
            CATEGORY
          </p>
          <p class="mt-3 text-lg text-grey-900 font-bold">
            {{ productInfo.categoryName }}
          </p>
        </div>
      </div>
    </section>

    <section id="choose_your_medium" class="container mt-16 md:mt-36">
      <div class="text-center">
        <div
          class="text-xs font-normal text-grey-700 tracking-widest uppercase"
        >
          DIFFERENT APPLICATIONS
        </div>
        <div class="text-2xl font-bold text-grey-900">Also Available as</div>
      </div>

      <div
        class="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-4 md:gap-x-3 lg:gap-x-4 gap-y-16"
      >
        <a
          :href="
            $router.resolve({
              name: 'product',
              params: { id: product.productId },
            }).href
          "
          class="group"
          v-for="(product, index) in products"
          :key="index"
        >
          <div
            class="border border-grey-100 p-4 block h-72 sm:h-72 md:h-56 lg:h-72 xl:h-96 hover:border-grey-300 transform duration-700"
          >
            <img :src="product.image" alt="" class="w-full h-full" />
          </div>

          <span
            class="mt-8 text-sm text-grey-500 font-normal block text-center"
          >
            {{ product.productName }}
          </span>

          <span class="mt-2 block text-center">
            <router-link
              :to="{ name: 'producer', params: { id: product.producerId } }"
              class="text-lg text-grey-900 font-bold inline-block hover:underline"
            >
              {{ product.producerName }}
            </router-link>
          </span>
        </a>
      </div>
    </section>

    <section id="Reviews" class="bg-surface-grey py-12 mt-32">
      <div class="container overflow-hidden">
        <div class="text-center">
          <div
            class="text-xs font-normal text-grey-700 tracking-widest uppercase"
          >
            People Baught this product
          </div>
          <div class="text-2xl font-bold text-grey-900 mt-3">Reviews</div>
        </div>

        <div v-if="reviews.length == 0" class="text-center my-6 text-grey-400">
          There is no reviews yet.
        </div>

        <div v-else class="glide" id="glide1" dir="ltr">
          <div class="glide__track pb-12 px-2" data-glide-el="track">
            <ul class="glide__slides flex space-x-8">
              <li
                v-for="(review, index) in reviews"
                :key="index"
                class="border border-grey-100 p-5 max-w-xs w-full hidden lg:block"
              >
                <div class="flex justify-between items-center">
                  <p class="text-primary-black text-sm">
                    {{ review.createdBy }}
                  </p>

                  <p class="text-grey-500 text-xs">{{ review.createdOn }}</p>
                </div>
                <div class="space-x-1 flex items-center mt-2">
                  <span v-for="(rate, index) in review.rate" :key="index">
                    <svg
                      class="fill-current text-yellow-100"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 0L9.26336 5.07295L14.6329 5.18237L10.3532 8.42705L11.9084 13.5676L7.5 10.5L3.09161 13.5676L4.64683 8.42705L0.367076 5.18237L5.73664 5.07295L7.5 0Z"
                        fill="#F8C71C"
                      />
                    </svg>
                  </span>
                </div>
                <p class="text-primary-black text-sm leading-8 mt-4">
                  {{ review.description }}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div class="flex justify-center items-center mt-12">
          <button
            @click="toggleReview()"
            class="text-base text-grey-900 font-normal px-8 lg:px-12 py-2 lg:py-3 border border-primary-black rounded hover:text-surface-white hover:bg-primary-black transform duration-700"
          >
            ADD REVIEW
          </button>
        </div>
      </div>
    </section>

    <section id="Featured-artworks" class="container mt-14">
      <div class="text-center">
        <div
          class="text-xs font-normal text-grey-700 tracking-widest uppercase"
        >
          you might also like
        </div>
        <div class="text-2xl font-bold text-grey-900">Related Artworks</div>
      </div>

      <div
        class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:gap-x-3 lg:gap-x-4 gap-y-10 w-full mt-10"
      >
        <router-link
          v-for="(artworkProducer, index) in artworksProducer"
          :key="index"
          :to="{ name: 'artwork', params: { id: artworkProducer.artworkId } }"
          class="group"
        >
          <div
            class="border border-grey-100 p-8 md:p-10 lg:p-20 block group-hover:border-grey-300 transform duration-700"
          >
            <img
              :src="artworkProducer.artWorkImage"
              alt=""
              class="w-full h-96 object-cover"
            />
          </div>

          <p
            class="mt-6 text-xl xl:text-2xl text-grey-900 font-bold block text-center"
          >
            {{ artworkProducer.artworkName }}
          </p>
          <p class="mt-4 text-sm text-grey-600 font-semibold block text-center">
            {{ artworkProducer.producerName }}
          </p>
        </router-link>
      </div>
    </section>

    <div
      id="reviewmodal"
      class="hidden fixed inset-0 bg-primary-black bg-opacity-75 z-50 h-full w-full flex justify-center items-center"
    >
      <button
        @click="toggleReview()"
        class="absolute top-10 right-10 focus:outline-none"
      >
        <svg
          class="w-8 h-8 hover:text-grey-200 duration-300 stroke-current text-surface-white"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6772 22.1914C18.2001 22.1914 22.6772 17.7143 22.6772 12.1914C22.6772 6.66856 18.2001 2.19141 12.6772 2.19141C7.1544 2.19141 2.67725 6.66856 2.67725 12.1914C2.67725 17.7143 7.1544 22.1914 12.6772 22.1914Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.6772 9.19141L9.67725 15.1914"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.67725 9.19141L15.6772 15.1914"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div class="px-16 py-6 bg-surface-white z-50">
        <!-- <p>ADD REVIEW</p> -->
        <div class="">
          <input
            type="text"
            v-model="reviewInfo.description"
            class="mt-6 border-b border-t-0 border-l-0 border-r-0 border-grey-500 w-full pb-3 form-input focus:outline-none placeholder-grey-400 text-sm"
            placeholder="Write comment here."
          />

          <div class="mt-4">
            <div class="flex">
              <div class="mx-auto">
                <div class="my-4">
                  <div class="rating">
                    <input
                      type="radio"
                      name="rating-star2"
                      class="rating__control screen-reader"
                      id="rc1"
                    />
                    <input
                      type="radio"
                      name="rating-star2"
                      class="rating__control screen-reader"
                      id="rc2"
                    />
                    <input
                      type="radio"
                      name="rating-star2"
                      class="rating__control screen-reader"
                      id="rc3"
                    />
                    <input
                      type="radio"
                      name="rating-star2"
                      class="rating__control screen-reader"
                      id="rc4"
                      checked
                    />
                    <input
                      type="radio"
                      name="rating-star2"
                      class="rating__control screen-reader"
                      id="rc5"
                    />
                    <label
                      @click="selectStar(1)"
                      for="rc1"
                      class="relative cursor-pointer px-2 rating__item"
                    >
                      <svg class="block w-4 h-4 fill-current text-gray-800">
                        <use xlink:href="#star"></use>
                      </svg>
                      <span class="screen-reader">1</span>
                    </label>
                    <label
                      @click="selectStar(2)"
                      for="rc2"
                      class="relative cursor-pointer px-2 rating__item"
                    >
                      <svg class="block w-4 h-4 fill-current text-gray-800">
                        <use xlink:href="#star"></use>
                      </svg>
                      <span class="screen-reader">2</span>
                    </label>
                    <label
                      @click="selectStar(3)"
                      for="rc3"
                      class="relative cursor-pointer px-2 rating__item"
                    >
                      <svg class="block w-4 h-4 fill-current text-gray-800">
                        <use xlink:href="#star"></use>
                      </svg>
                      <span class="screen-reader">3</span>
                    </label>
                    <label
                      @click="selectStar(4)"
                      for="rc4"
                      class="relative cursor-pointer px-2 rating__item"
                    >
                      <svg class="block w-4 h-4 fill-current text-gray-800">
                        <use xlink:href="#star"></use>
                      </svg>
                      <span class="screen-reader">4</span>
                    </label>
                    <label
                      @click="selectStar(5)"
                      for="rc5"
                      class="relative cursor-pointer px-2 rating__item"
                    >
                      <svg class="block w-4 h-4 fill-current text-gray-800">
                        <use xlink:href="#star"></use>
                      </svg>
                      <span class="screen-reader">5</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
              <symbol id="star" viewBox="0 0 26 28">
                <path
                  d="M26 10.109c0 .281-.203.547-.406.75l-5.672 5.531 1.344 7.812c.016.109.016.203.016.313 0 .406-.187.781-.641.781a1.27 1.27 0 0 1-.625-.187L13 21.422l-7.016 3.687c-.203.109-.406.187-.625.187-.453 0-.656-.375-.656-.781 0-.109.016-.203.031-.313l1.344-7.812L.39 10.859c-.187-.203-.391-.469-.391-.75 0-.469.484-.656.875-.719l7.844-1.141 3.516-7.109c.141-.297.406-.641.766-.641s.625.344.766.641l3.516 7.109 7.844 1.141c.375.063.875.25.875.719z"
                />
              </symbol>
            </svg>
          </div>
        </div>

        <div class="w-full flex justify-center items-center mt-6">
          <button
            @click="submit()"
            class="text-base text-grey-900 font-normal px-8 lg:px-12 py-2 border border-primary-black rounded hover:text-surface-white hover:bg-primary-black transform duration-700"
          >
            ADD
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Glide from "@glidejs/glide";

export default {
  components: {},
  computed: {
      filters() {
       return [
        ...new Map(this.productInfo.colors.map((item) => [item.colorId, item])).values(),
       ];
                

    },
  },
  mounted() {
    window.scroll(0, 0);
    this.GetProductById();
    this.GetReviews();
  },
  data() {
    return {
      productId: this.$route.params.id,
      sizes: [],
      productInfo: {},
      productImages: {},
      coloradded: "",
      productImage: "",
      indexOfProductImage: 0,
      colors: [],
      quantity: 1,
      size: "",
      decreaseQuantity: false,
      addQuantity: true,
      price: "",
      totelQty: "",
      filter: {
        pageNo: 1,
        pageSize: 10,
        productId: this.$route.params.id,
      },
      totel: 0,
      reviews: [],
      reviewInfo: {
        description: "",
        rate: 4,
        productId: Number(this.$route.params.id),
      },
      addSuccessed: "",
      artworksProducer: [],
      producerId: "",
      products: [],
      categoryId: "",

       sizeTypeId: "",
    };
  },
  methods: {
    selectStar(star) {
      this.reviewInfo.rate = star;
    },

    toggleReview() {
      document.getElementById("reviewmodal").classList.toggle("hidden");
      document.body.classList.toggle("overflow-y-hidden");
    },

    addIndexOfProductImages() {
      if (this.indexOfProductImage < this.productImages.length - 1) {
        var index = this.indexOfProductImage + 1;
        this.indexOfProductImage = index;
        this.productImage = this.productImages[index].productImage;
      } else {
        if (this.indexOfProductImage == this.productImages.length - 1) {
          this.indexOfProductImage = 0;
          this.productImage = this.productImages[0].productImage;
        }
      }
    },

    subIndexOfProductImages() {
      if (this.indexOfProductImage > 0) {
        var index = this.indexOfProductImage - 1;
        this.indexOfProductImage = index;
        this.productImage = this.productImages[index].productImage;
      } else {
        this.indexOfProductImage = this.productImages.length - 1;
        this.productImage = this.productImages[
          this.indexOfProductImage
        ].productImage;
      }
    },

    GetProductById() {
      this.$http.productsService
        .GetProductById(this.productId)
        .then((res) => {
          this.productInfo = res.data.result.product;
          this.productImages = res.data.result.productImages;
          this.productImage = this.productImages[0].productImage;
          this.colors = res.data.result.product.colors;
          this.producerId = res.data.result.product.producerId;
          this.categoryId = res.data.result.product.categoryId;

          this.sizeTypeId = res.data.result.product.sizeTypeId;
          this.getProductBycatagory();
          this.GetArtworkByproducer();
          })
        .catch((err) => {
          console.log(err);
        });
    },
 
    changeMainPicture(imge, index) {
      this.productImage = imge;
      this.indexOfProductImage = index;
    },

    addToCart() {
      this.$store.dispatch("addProductToCart", {
        productId: Number(this.productId),
        productAttributeId: this.productInfo.productAttributeId,
        productName: this.productInfo.productName,
        productPrice: this.size.price,
        productQty: this.quantity,
        productImage: this.productImage,
        productColor: this.coloradded.colorId,
        color: this.coloradded,
        size: this.size,
        sizeId: Number(this.size.sizeId),
        SizeTypeId: this.size.sizeType,
        height: Number(this.size.height),
        width: Number(this.size.width),
      });
    },

    Addcolor(color) {
      this.coloradded = color;
      this.sizes = color.sizes;
      this.size = 0;
    },

    choicesize(size) {
      this.size = size;
      // if( this.sizes.includes(size) ){
      //   console.log("true")
      // }
    },

    quantityFun(number) {
      if (number === "negative") {
        this.addQuantity = true;
        if (this.quantity == 1) {
          this.decreaseQuantity = false;
          return (this.quantity = 1);
        }
        this.quantity--;
      } else if (number === "positive") {
        this.decreaseQuantity = true;
        if (this.quantity == this.size.qty) {
          this.addQuantity = false;
          return (this.quantity = this.size.qty);
        }
        this.quantity++;
      }
    },

    GetReviews() {
      this.$http.reviewsService
        .GetReviews(this.filter)
        .then((res) => {
          this.totel = res.data.result.total;
          this.reviews = res.data.result.reviews;

          if (this.reviews.length) {
            setTimeout(function () {
              new Glide("#glide1", {
                type: "carousel",
                perView: 3.2,
                focusAt: "center",
                bound: true,
                autoplay: 2000,
                breakpoints: {
                  1100: {
                    perView: 2.2,
                  },
                  600: {
                    perView: 1.2,
                  },
                },
              }).mount();
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submit() {
      this.$http.reviewsService
        .AddReview(this.reviewInfo)
        .then((res) => {
          this.addSuccessed = res.data.result.message;
          this.toggleReview();
          this.GetReviews();
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },

    GetArtworkByproducer() {
      this.$http.producersService
        .GetArtworkByproducer(this.producerId)
        .then((res) => {
          this.data = res.data;
          this.artworksProducer = res.data.result.artworksProducer;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getProductBycatagory() {
      this.$http.reviewsService
        .GetProductBycatagory(Number(this.categoryId), this.productId)
        .then((res) => {
          this.products = res.data.result.productsInfo;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.glide__slides {
  padding: 30px 0;
}
.screen-reader {
  width: var(--screenReaderWidth, 1px) !important;
  height: var(--screenReaderHeight, 1px) !important;
  padding: var(--screenReaderPadding, 0) !important;
  border: var(--screenReaderBorder, none) !important;

  position: var(--screenReaderPosition, absolute) !important;
  clip: var(--screenReaderClip, rect(1px, 1px, 1px, 1px)) !important;
  overflow: var(--screenReaderOverflow, hidden) !important;
}

.rating {
  --uiRatingColor: var(--ratingColor, #eee);
  --uiRatingColorActive: var(--ratingColorActive, #ffcc00);

  display: var(--ratingDisplay, flex);
  font-size: var(--ratingSize, 1rem);
  color: var(--uiRatingColor);
}

.rating__control:nth-of-type(1):focus ~ .rating__item:nth-of-type(1)::before,
.rating__control:nth-of-type(2):focus ~ .rating__item:nth-of-type(2)::before,
.rating__control:nth-of-type(3):focus ~ .rating__item:nth-of-type(3)::before,
.rating__control:nth-of-type(4):focus ~ .rating__item:nth-of-type(4)::before,
.rating__control:nth-of-type(5):focus ~ .rating__item:nth-of-type(5)::before {
  content: "";
  /* box-shadow: 0 0 0 var(--ratingOutlineWidth, 4px) var(--uiRatingColorActive); */

  position: absolute;
  top: -0.15em;
  right: 0;
  bottom: -0.15em;
  left: 0;
  z-index: -1;
}

.rating:hover,
.rating__control:nth-of-type(1):checked ~ .rating__item:nth-of-type(1),
.rating__control:nth-of-type(2):checked ~ .rating__item:nth-of-type(-n + 2),
.rating__control:nth-of-type(3):checked ~ .rating__item:nth-of-type(-n + 3),
.rating__control:nth-of-type(4):checked ~ .rating__item:nth-of-type(-n + 4),
.rating__control:nth-of-type(5):checked ~ .rating__item:nth-of-type(-n + 5) {
  color: var(--uiRatingColorActive);
}

.rating__item:hover ~ .rating__item {
  color: var(--uiRatingColor);
}

.rating {
  --ratingSize: 2rem;
  --ratingColor: #eee;
  --ratingColorActive: #ffcc00;
}
</style>